import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import CustomTab from '../components/CustomTab';
import SubPageHeader from '../components/SubPageHeader';
import partExampleImg from '../assets/images/140.png';

export default function BuildingStovesDetailsPage({ data, pageContext }) {
  const details = data.stove;
  const breadcrumbs = [
    {
      title: 'Building',
      url: '/building',
    },
    {
      title: pageContext.familyName,
      url: `/building/${pageContext.familyUrl}`,
    },
  ];
  const parts = [];
  details.parts.map((item) =>
    parts.push({
      quantity: item.quantity,
      comment: item.comment,
      article_number: item.building_parts.article_number,
      name: item.building_parts.name,
      image:
        item.building_parts.image != null
          ? item.building_parts.image.asset.fluid.src
          : partExampleImg,
    })
  );

  return (
    <Layout>
      <SubPageHeader title={pageContext.name} breadcrumbs={breadcrumbs} />
      <CustomTab
        parts={parts}
        gallery={details.building_gallery}
        video={details.building_video}
      />
    </Layout>
  );
}

export const query = graphql`
  query BuildingStoveQuery($url: String) {
    stove: sanityStove(url: { current: { eq: $url } }) {
      building_video
      id
      family {
        name
        url {
          current
        }
        id
      }
      building_gallery {
        description
        alt
        image {
          asset {
            fluid {
              src
            }
          }
        }
      }
      parts {
        building_parts {
          ... on SanityPart {
            id
            name
            article_number
            image {
              asset {
                fluid(maxWidth: 400) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
        quantity
        comment
      }
    }
  }
`;
